<template>
  <div class="p-2">
    <div class="pb-1 flex items-center">
      <button
        @click="prevWeek"
        type="button"
        :class="{ 'invisible': offset === 0 }"
        class="p-2 rounded-full hover:bg-gray-300 focus:bg-gray-300 focus:outline-none"
      >
        <app-icon icon="icon-navigate_before" class="h-6 w-6"></app-icon>
      </button>
      <div class="flex-grow text-center text-sm font-semibold">{{ label }}</div>
      <button
        @click="nextWeek"
        type="button"
        class="p-2 rounded-full hover:bg-gray-300 focus:bg-gray-300 focus:outline-none"
      >
        <app-icon icon="icon-navigate_next" class="h-6 w-6"></app-icon>
      </button>
    </div>
    <ul class="flex justify-around list-reset">
      <li v-for="date in week" :key="date.index" class="text-center">
        <input
          type="radio"
          :id="`date-${date.index}`"
          name="date"
          :checked="date.iso === value"
          @change="$emit('input', date.iso)"
          class="absolute opacity-0 pointer-events-none"
        >
        <label :for="`date-${date.index}`" class="block w-10 cursor-pointer">
          <div class="text-sm">{{ date.dayOfWeek }}</div>
          <div
            :class="[date.iso === value ? 'bg-teal-700 text-white' : 'hover:bg-gray-300']"
            class="flex items-center justify-center h-10 font-semibold rounded-full"
          >{{ date.dayOfMonth }}</div>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import AppIcon from '@/components/AppIcon';

export default {
  components: {
    AppIcon,
  },

  props: ['value'],

  data() {
    return {
      offset: 0,
      weekdays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
    };
  },

  computed: {
    week() {
      const today = new Date();
      today.setDate(today.getDate() + this.offset * 7);
      const week = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(today.setDate(today.getDate() + 1));
        week.push({
          index: this.offset * 7 + i,
          iso: date.toISOString().split('T')[0],
          dayOfWeek: this.weekdays[date.getDay()],
          dayOfMonth: date.getDate(),
          month: date.getMonth(),
          year: date.getFullYear(),
        });
      }
      return week;
    },

    label() {
      const startMonth = this.months[this.week[0].month];
      const endMonth = this.months[this.week[6].month];
      const startYear = this.week[0].year;
      const endYear = this.week[6].year;
      if (startMonth === endMonth) {
        return `${startMonth} ${startYear}`;
      }
      if (startYear === endYear) {
        return `${startMonth} / ${endMonth} ${startYear}`;
      }
      const shortStartYear = String(startYear).slice(-2);
      return `${startMonth} ${shortStartYear} / ${endMonth} ${endYear}`;
    },
  },

  methods: {
    prevWeek() {
      this.foo;
      if (this.offset < 1) return;
      this.offset--;
    },
    nextWeek() {
      this.offset++;
    },
  },
};
</script>
