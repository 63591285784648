<template>
  <div class="flex flex-col">
    <section class="flex-grow w-full max-w-5xl mx-auto py-4">
      <h1 class="heading text-2xl text-center mx-4 mb-4">Vielen Dank für Ihren Einkauf!</h1>
      <p class="m-4 text-lg text-center">Ihren Gutschein erhalten Sie in Kürze per E-Mail.</p>
      <div class="max-w-md mx-auto p-4">
        <img
          src="https://res.cloudinary.com/kt/image/upload/v1608570813/massage/voucher.svg"
          alt="Geschenkkarte"
        />
      </div>
      <div class="m-4 text-right">
        <router-link :to="{ name: 'home' }" class="text-black">zurück zur Starseite</router-link>
      </div>
    </section>
    <contact></contact>
  </div>
</template>

<script>
import Contact from '@/components/Contact';

export default {
  components: {
    Contact,
  },
  created() {
    this.$store.commit('clearCart')
  },
};
</script>
