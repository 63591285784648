<template>
  <div v-show="show" class="fixed inset-x-0 bottom-0 bg-teal-900 text-white text-sm leading-tight z-10">
    <div class="max-w-5xl mx-auto my-2 pl-4 flex items-center justify-between">
      <p>
        Diese Website setzt zur Optimierung Cookies ein. Durch die weitere Nutzung stimmen Sie dem Einsatz von Cookies zu.
        <a
          href="/datenschutz"
          class="text-gray-300 underline"
        >Mehr Informationen</a>
      </p>
      <div>
        <button
          @click="dismiss"
          type="button"
          class="bg-white text-teal-900 font-bold rounded mx-4 px-3 py-2"
        >OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import cookies from '@/cookies';

export default {
  data() {
    return {
      name: 'cookieconsent_status',
      status: null,
    };
  },

  computed: {
    show() {
      if (this.status === 'dismiss') return false;
      if (cookies.getItem(this.name) === 'dismiss') return false;
      return true;
    },
  },

  methods: {
    dismiss() {
      this.status = 'dismiss';
      cookies.setItem(this.name, this.status, 'Tue, 19 Jan 2038 03:14:07 GMT');
    },
  },
};
</script>

<style>
</style>
