<template>
  <ul class="list-reset mb-4">
    <li v-for="category in categories" :key="category.id">
      <div class="text-xs font-semibold bg-gray-100 px-3 py-1">{{ category.label }}</div>
      <ul class="list-reset">
        <li v-for="type in category.types" :key="type.id" class="relative">
          <input
            type="radio"
            :id="`massage-${type.id}`"
            name="massage"
            :checked="type.id === value.id"
            @change="$emit('input', type)"
            class="absolute h-full opacity-0 pointer-events-none"
          />
          <label
            :for="`massage-${type.id}`"
            :class="[type.id === value.id ? 'bg-teal-700 text-white' : 'hover:bg-gray-200']"
            class="block cursor-pointer px-4 py-2"
          >
            <div class="font-semibold leading-tight">{{ type.name }}</div>
            <div class="flex justify-between text-sm">
              <div>{{ type.duration }} Min.</div>
              <div>{{ formatPrice(type.price) }}</div>
            </div>
          </label>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['value'],

  computed: {
    categories() {
      return this.$store.state.massageCategories;
    },
  },

  methods: {
    formatPrice(value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(value);
    },
  },
};
</script>
