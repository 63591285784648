<template>
  <div>
    <input
      type="radio"
      id="value-voucher"
      name="massage"
      :checked="'value-voucher' === value.id"
      @change="setValueVoucher"
      class="absolute h-full opacity-0 pointer-events-none"
    />
    <label
      for="value-voucher"
      :class="['value-voucher' === value.id ? 'bg-teal-700 text-white' : 'hover:bg-gray-200']"
      class="block cursor-pointer px-4 py-2"
    >
      <label for="amount" class="pl-2">Höhe des Gutscheinwertes</label>
      <input
        id="amount"
        type="number"
        step="5"
        min="20"
        max="1000"
        class="input mb-2"
        v-model="amount"
        placeholder="min. 20 €"
        @focus="setValueVoucher"
        @change="setValueVoucher"
      />
    </label>
  </div>
</template>

<script>
export default {
  props: ['value'],

  data() {
    return {
      amount: null,
    };
  },

  computed: {},

  methods: {
    setValueVoucher() {
      this.$emit('input', {
        id: 'value-voucher',
        name: 'Wertgutschein',
        price: this.amount,
      });
    },
  },
};
</script>
