<template>
  <div id="app" class="flex flex-col min-h-screen">
    <router-view name="header"/>
    <router-view class="flex-grow w-full"/>
    <footer class="bg-black text-sm pb-24 sm:pb-12">
      <div class="max-w-5xl mx-auto flex items-center flex-wrap justify-end px-2 py-4">
        <router-link
          :to="{ name: 'home' }"
          class="no-underline px-2 py-1 text-white hover:underline focus:outline-none focus:underline"
        >Massage & Meer</router-link>
        <router-link
          :to="{ name: 'legal' }"
          class="no-underline px-2 py-1 text-white hover:underline focus:outline-none focus:underline"
        >Impressum</router-link>
        <router-link
          :to="{ name: 'privacy' }"
          class="no-underline px-2 py-1 text-white hover:underline focus:outline-none focus:underline"
        >Datenschutz</router-link>
        <a
          title="Instagram"
          href="https://www.instagram.com/wellnessdeck/"
          target="_blank"
          class="flex items-center no-underline px-2 py-1 text-white hover:underline focus:outline-none focus:underline"
        ><app-icon icon="icon-instagram" class="w-6 h-6 mr-2"></app-icon></a>
      </div>
    </footer>
    <cookie-consent></cookie-consent>
    <app-icons></app-icons>
  </div>
</template>

<script>
import AppIcons from '@/components/AppIcons';
import AppIcon from '@/components/AppIcon';
import CookieConsent from '@/components/CookieConsent';

export default {
  components: {
    AppIcons,
    AppIcon,
    CookieConsent,
  },
};
</script>
