import Vue from 'vue';
import Vuex from 'vuex';

import products from '../data/products';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    products: products,
    appointment: {
      date: null,
      time: null,
    },
    massage: {},
    location: {
      address: null,
      postcode: null,
      city: null,
    },
    contact: {
      name: null,
      email: null,
      phone: null,
      info: null,
    },
    cart: [],
    massageCategories: products.data,
  },
  mutations: {
    initialiseStore(state) {
      const cart = JSON.parse(localStorage.getItem('cart'));
      if (Array.isArray(cart)) {
        state.cart = cart;
      }
    },
    updateDate(state, date) {
      state.appointment.date = date || null;
    },
    updateTime(state, time) {
      state.appointment.time = time || null;
    },
    updateMassage(state, massage) {
      state.massage = massage || null;
    },
    updateAddress(state, address) {
      state.location.address = address || null;
    },
    updatePostcode(state, postcode) {
      state.location.postcode = postcode || null;
    },
    updateCity(state, city) {
      state.location.city = city || null;
    },
    updateName(state, name) {
      state.contact.name = name || null;
    },
    updateEmail(state, email) {
      state.contact.email = email || null;
    },
    updatePhone(state, phone) {
      state.contact.phone = phone || null;
    },
    updateInfo(state, info) {
      state.contact.info = info || null;
    },
    addItemToCart(state, item) {
      state.cart.push(item);
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    removeFromCart(state, index) {
      state.cart.splice(index, 1);
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    clearCart(state) {
      state.cart = [];
      localStorage.removeItem('cart');
    },
  },
  actions: {},
  getters: {
    total: state => {
      return state.cart.reduce((acc, cur) => {
        return acc + parseInt(cur.price);
      }, 0);
    }
  }
});

const initialStateCopy = JSON.parse(JSON.stringify(store.state));
export function resetState() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}

export default store;
