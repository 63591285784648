<template>
  <div>
    <tour-banner></tour-banner>
    <section class="max-w-5xl mx-auto pt-12">
      <div class="flex flex-wrap items-end">
        <div class="flex-1 text-center m-4 sm:max-w-xs">
          <img
            alt="Wellnesdeck"
            width="480"
            height="521"
            src="https://res.cloudinary.com/kt/image/upload/c_scale,f_auto,q_85,w_720/v1536060727/massage/hotel-hafen"
            class="inline-block min-w-64 rounded shadow"
          >
        </div>
        <div class="sm:flex-1 p-4">
          <h2
            class="heading text-2xl mb-4"
          >Ruhe und Entspannung genießen – direkt an der Förde, im Herzen der Stadt</h2>
          <h3
            class="text-base font-medium"
          >Ayurveda, Thai Massage, Pantai Luar, Lomi Lomi oder klassische Massage:</h3>
          <p>Genießen Sie unterschiedlichste Anwendungen und gönnen Sie Ihrem Körper eine wohlverdiente Auszeit.</p>
        </div>
      </div>
    </section>
    <section class="max-w-5xl mx-auto pb-4">
      <p
        class="m-4"
      >Auf dem Wellnessdeck kümmert sich Gebhard Hock mit seinem Team um Ihr Wohlbefinden. Der seit über 20 Jahren tätige Ayurveda-Massagetherapeut beherrscht eine große Vielfalt unterschiedlicher Anwendungen.</p>
      <p
        class="m-4"
      >Regionalgäste sind bei uns herzlich willkommen. Lassen Sie sich von uns verwöhnen! Nach einer Massageanwendung kann die Sauna und der Ruhebereich kostenfrei genutzt werden. Selbstverständlich können Sie alle Anwendungen als Gutschein verschenken.</p>
      <div class="flex flex-wrap items-center">
        <div class="sm:flex-1 p-4">
          <ul class="mr-4 list-reset text-lg tracking-wide">
            <li class="my-1 flex">
              <app-icon icon="icon-check" class="w-6 h-6 mr-2"></app-icon>Massageanwendungen für Hotel- und Regionalgäste
            </li>
            <li class="my-1 flex">
              <app-icon icon="icon-check" class="w-6 h-6 mr-2"></app-icon>finnische Sauna
            </li>
            <li class="my-1 flex">
              <app-icon icon="icon-check" class="w-6 h-6 mr-2"></app-icon>Gutscheine
            </li>
            <li class="my-1 flex">
              <app-icon icon="icon-check" class="w-6 h-6 mr-2"></app-icon>Flensburger Hafen Peeling
            </li>
          </ul>
        </div>
        <div class="flex-1 p-4 text-center">
          <img
            alt="Wellnesdeck"
            width="842"
            height="556"
            src="https://res.cloudinary.com/kt/image/upload/c_scale,f_auto,q_85,w_842/v1536060727/massage/wellnessdeck"
            class="inline-block min-w-64 rounded shadow"
          >
        </div>
      </div>
    </section>
    <section class="max-w-5xl mx-auto pb-4">
      <h2 class="heading text-2xl text-center mx-4">Termin vereinbaren</h2>
      <div class="flex flex-wrap justify-center items-center">
        <div class="flex-shrink-0 p-4">
          <a
            href="tel:+4946116068460"
            title="Festnetz"
            class="block flex items-center p-1 text-inherit font-light text-lg no-underline hover:text-gray-700"
          >
            <app-icon icon="icon-phone" class="w-6 h-6 mr-2"></app-icon>+49 461 16068460
          </a>
          <a
            href="tel:+491627772012"
            title="Mobil"
            class="block flex items-center p-1 text-inherit font-light text-lg no-underline hover:text-gray-700"
          >
            <app-icon icon="icon-smartphone" class="w-6 h-6 mr-2"></app-icon>+49 162 7772012
          </a>
        </div>
        <div class="flex-shrink-0 text-center p-4">
          <router-link
            :to="{ name: 'date-intern' }"
            class="btn text-white bg-teal-700 hover:bg-teal-900 focus:bg-teal-900"
          >Termin online anfragen</router-link>
        </div>
      </div>
      <div class="flex flex-wrap m-4 bg-white rounded shadow overflow-hidden">
        <div class="flex-grow">
          <iframe
            title="Map - Hotel Hafen Flensburg"
            class="block"
            width="100%"
            height="420"
            frameborder="0"
            style="border:0"
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJwxJQeL9Cs0cRUH_6HPcNyjA&key=AIzaSyAmZOAjcmaCAKh25psjQLnQ2gUmtZfxbn4"
            allowfullscreen
          ></iframe>
        </div>
        <div class="p-4">
          <div class="font-bold mb-1">
            Wellnessdeck
            <span class="whitespace-no-wrap">Hotel Hafen Flensburg</span>
          </div>
          <div>Schiffbrücke 33</div>
          <div class="mb-2">24939 Flensburg</div>
          <div>
            <a
              href="mailto:wellness@hotel-hafen-flensburg.de"
              class="text-inherit no-underline hover:underline"
            >wellness@hotel-hafen-flensburg.de</a>
          </div>
          <div>
            <a
              href="https://www.hotel-hafen-flensburg.de/"
              target="_blank"
              rel="noopener"
              class="text-inherit no-underline hover:underline"
            >www.hotel-hafen-flensburg.de</a>
          </div>
          <div>
            <router-link
              :to="{ name: 'home' }"
              class="text-inherit no-underline hover:underline"
            >www.massage-meer.de</router-link>
          </div>
          <div class="mt-4 mb-1 font-semibold">Parkmöglichkeiten:</div>
          <p>Parkplätze finden Sie am Hafen,
            <br>direkt gegenüber vom Hotel.
          </p>
        </div>
      </div>
    </section>
    <section class="bg-white py-4">
      <div class="max-w-5xl mx-auto">
        <h2
          id="massage-types"
          class="heading text-2xl text-center mx-4"
        >Übersicht der Massageanwendungen</h2>
        <massage-types></massage-types>
      </div>
    </section>
    <contact></contact>
  </div>
</template>
<script>
import AppIcon from '@/components/AppIcon';
import TourBanner from '@/components/TourBanner';
import MassageTypes from '@/components/MassageTypes';
import Contact from '@/components/Contact';

export default {
  components: {
    AppIcon,
    TourBanner,
    MassageTypes,
    Contact,
  },
};
</script>
