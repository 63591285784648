<template>
  <div class="sm:flex flex-wrap max-w-5xl mx-auto mt-2 mb-8">
    <form @submit.prevent="addToCart" method="post" class="flex flex-col flex-1">
      <h2 class="text-lg mx-4 mt-4 font-medium">Gutschein über Massage</h2>
      <div class="bg-white rounded shadow mx-4 my-2 h-72 overflow-y-scroll">
        <massage-picker v-model="massage"></massage-picker>
      </div>
      <h2 class="text-lg mx-4 mt-4 font-medium">Wertgutschein</h2>
      <div class="bg-white rounded shadow mx-4 my-2 relative overflow-hidden">
        <value-voucher-picker v-model="massage"></value-voucher-picker>
      </div>
      <div class="flex justify-end m-4">
        <button
          :disabled="disableAddBtn"
          class="btn text-white bg-teal-700 hover:bg-teal-900 focus:bg-teal-900"
        >In den Warenkorb</button>
      </div>
    </form>
    <div class="flex flex-col flex-1">
      <shopping-cart></shopping-cart>
      <div class="bg-white rounded shadow mx-4 my-2 p-4">
        <div class="mb-4 mx-1">
          <label for="email" class="pl-2">E-Mail</label>
          <input
            id="email"
            name="email"
            type="email"
            v-model="email"
            placeholder="example@domain.com"
            class="input"
            :class="emailClass"
          />
        </div>
      </div>
      <div>
        <h2 class="text-lg mx-4 mt-4 font-medium">Zahlung</h2>
        <div class="bg-white rounded shadow mx-4 my-2 p-4">
          <div id="paypal-button-container" class="relative z-10"></div>
        </div>
      </div>
    </div>
    <error-alert :show="submitError" @close="submitError = false" class="z-30"></error-alert>
    <div
      v-if="loading"
      class="fixed inset-0 bg-black bg-opacity-90 z-20 flex items-center justify-center w-full"
    >
      <div class="bg-white p-8 rounded">
        <p>Zahlung wird verarbeitet.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MassagePicker from '@/components/MassagePicker';
import ValueVoucherPicker from '@/components/ValueVoucherPicker';
import ShoppingCart from '@/components/ShoppingCart';
import ErrorAlert from '@/components/ErrorAlert';

export default {
  components: {
    MassagePicker,
    ValueVoucherPicker,
    ShoppingCart,
    ErrorAlert,
  },

  data() {
    return {
      actions: null,
      massage: {},
      loading: false,
      renderError: false,
      submitError: false,
    };
  },

  watch: {
    disableSubmitBtn(newState) {
      if (newState === false) this.actions.enable();
      if (newState === true) this.actions.disable();
    },
  },

  computed: {
    disableAddBtn() {
      if (!this.massage.price) {
        return true;
      }
      return !this.massage.id;
    },
    disableSubmitBtn() {
      if (this.loading) return true;
      if (this.submitError) return true;
      if (this.items.length < 1) return true;
      if (!this.email) return true;
      return false;
    },
    items() {
      return this.$store.state.cart.map((item) => {
        return { id: item.id, amount: item.price };
      });
    },
    email: {
      get() {
        return this.$store.state.contact.email;
      },
      set(value) {
        this.$store.commit('updateEmail', value);
      },
    },
    emailClass() {
      return {
        'error': !this.email && this.renderError,
      };
    },
  },

  mounted() {
    this.renderPayPal();
  },

  methods: {
    addToCart() {
      this.$store.commit('addItemToCart', this.massage);
      this.massage = {};
    },

    renderPayPal() {
      paypal
        .Buttons({
          onInit: (data, actions) => {
            if (this.disableSubmitBtn) {
              actions.disable();
            }
            this.actions = actions;
          },
          onClick: (data, actions) => {
            if (!this.email) {
              this.renderError = true;
            }
          },
          // Call your server to set up the transaction
          createOrder: async (data, actions) => {
            try {
              const res = await axios.post(
                process.env.VUE_APP_PAYPAL_ORDER_CREATE_URL,
                {
                  email: this.email,
                  items: this.items,
                  total: this.$store.getters.total,
                },
              );
              return res.data.id;
            } catch (error) {
              console.error(error);
            }
          },

          onCancel: () => {},

          // Call your server to finalize the transaction
          onApprove: async (data, actions) => {
            this.loading = true;
            const res = await axios.post(
              process.env.VUE_APP_PAYPAL_ORDER_CAPTURE_URL,
              { order_id: data.orderID },
            );
            this.loading = false;
            const orderData = res.data;
            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show confirmation or thank you
            // This example reads a v2/checkout/orders capture response, propagated from the server
            // You could use a different API or structure for your 'orderData'
            var errorDetail =
              Array.isArray(orderData.details) && orderData.details[0];
            if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
              return actions.restart(); // Recoverable state, per:

              // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
            }
            if (errorDetail) {
              var msg = 'Sorry, your transaction could not be processed.';
              if (errorDetail.description)
                msg += '\n\n' + errorDetail.description;
              if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
              return alert(msg); // Show a failure message
            }

            this.$router.replace({ name: 'shop-success' });
          },
        })
        .render('#paypal-button-container');
    },
  },
};
</script>
