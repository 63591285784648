<template>
  <section class="relative max-w-5xl mx-auto">
    <div class="md:absolute w-full px-2 sm:flex justify-center md:justify-end">
      <div class="flex flex-col md:w-52 mx-2 mt-4 md:-mt-4 px-4 bg-teal-700 text-white rounded shadow-md">
        <h2 class="mt-4 text-xl font-medium">Gutscheine</h2>
        <div class="text-sm flex-grow">jetzt online bestellen - mit PayPal bezahlen und sofort zu Hause ausdrucken.</div>
        <div class="text-right pt-3 pb-4">
          <router-link
            :to="{ name: 'shop' }"
            class="whitespace-nowrap no-underline inline-block text-teal-900 font-medium px-2 py-1 rounded bg-gray-50 hover:bg-white"
          >Zum Shop</router-link>
        </div>
      </div>
      <div class="flex flex-col md:w-52 mx-2 mt-4 md:-mt-4 px-4 bg-teal-700 text-white rounded shadow-md">
        <div class="text-sm font-bold tracking-wide pt-2">Wellnessdeck Spezial</div>
        <h2 class="mt-2 text-lg font-medium">Peeling + Ganzkörpermassage</h2>
        <div class="text-sm flex-grow"></div>
        <div class="text-right pt-3 pb-4">
          <a
            href="#special"
            class="whitespace-nowrap no-underline inline-block text-teal-900 font-medium px-2 py-1 rounded bg-gray-50 hover:bg-white"
          >Mehr Informationen</a>
        </div>
      </div>
      <div class="flex flex-col flex-shrink-0 mx-2 mt-4 md:-mt-4 px-4 bg-teal-700 text-white rounded shadow-md">
        <div class="text-sm font-bold tracking-wide pt-2">Termin vereinbaren</div>
        <div class="flex flex-wrap sm:block flex-grow">
          <a
            href="tel:+4946116068460"
            title="Festnetz"
            class="block flex items-center mr-4 sm:mr-0 my-1 py-1 text-inherit no-underline hover:text-gray-200"
          >
            <app-icon icon="icon-phone" class="w-6 h-6 mr-2"></app-icon>+49 461 16068460
          </a>
          <a
            href="tel:+491627772012"
            title="Mobil"
            class="block flex items-center my-1 py-1 text-inherit no-underline hover:text-gray-200"
          >
            <app-icon icon="icon-smartphone" class="w-6 h-6 mr-2"></app-icon>+49 162 7772012
          </a>
        </div>
        <div class="text-right pt-3 pb-4">
          <router-link
            :to="{ name: 'date-intern' }"
            class="no-underline inline-block text-teal-900 font-medium px-2 py-1 rounded bg-gray-50 hover:bg-white"
          >Online anfragen</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppIcon from '@/components/AppIcon';
export default {
  components: {
    AppIcon,
  },
};
</script>
