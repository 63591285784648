<template>
  <transition name="slide-fade">
    <div
      class="fixed inset-x-0 top-0 justify-center flex leading-tight pointer-events-none"
      v-if="show"
    >
      <div
        class="flex items-center m-4 p-2 bg-red-700 text-white text-sm rounded shadow-md pointer-events-auto"
      >
        <div
          class="p-2"
        >Ihre Anfrage konnte leider nicht bearbeitet werden. Bitte versuchen Sie es später erneut.</div>
        <button
          type="button"
          @click="close"
          class="text-white p-1 rounded hover:bg-red-500 focus:outline-none focus:bg-red-500"
        >
          <app-icon icon="icon-close" class="h-6 w-6"></app-icon>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import AppIcon from '@/components/AppIcon';

export default {
  components: {
    AppIcon,
  },

  props: ['show'],

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: transform 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: opacity 0.3s ease-out;
}
.slide-fade-enter {
  transform: translateY(-96px);
}
.slide-fade-leave-to {
  opacity: 0;
}
</style>
