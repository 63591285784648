import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  render: (h) => h(App),
}).$mount('#app');

window.onload = () => {
  if (router.currentRoute.hash) {
    setTimeout(() => {
      location.href = router.currentRoute.hash;
    }, 0);
  }
};
