<template>
  <div class="max-w-5xl mx-auto mt-2 mb-8">
    <router-view @submit="submit" :disable="disable" />
    <error-alert :show="submitError" @close="submitError = false"></error-alert>
  </div>
</template>

<script>
import ErrorAlert from '@/components/ErrorAlert';
import axios from 'axios';
import { resetState } from '@/store';

const url = `${process.env.VUE_APP_DB_URL}/requests.json`;

export default {
  components: {
    ErrorAlert,
  },

  data() {
    return {
      loading: false,
      submitError: false,
    };
  },

  computed: {
    disable() {
      if (this.loading) return true;
      if (this.submitError) return true;
      return false;
    },
  },

  methods: {
    async submit(data) {
      this.submitError = false;
      this.loading = true;

      try {
        const response = await axios.post(url, data);
        resetState();
        this.$router.push({ name: 'success' });
      } catch (error) {
        this.submitError = true;
        console.error(error);
      }
      this.loading = false;
    },
  },
};
</script>
