<template>
  <div>
    <video
      v-if="items[activeItem].videoId"
      controls
      height="480"
      style="height: 480px"
      poster="https://res.cloudinary.com/kt/video/upload/so_17/massage/thai.jpg"
      class="mx-auto max-w-full shadow rounded"
    >
      <source src="https://res.cloudinary.com/kt/video/upload/vc_auto/v1548849356/massage/thai" />
    </video>
    <img
      v-else
      :src="`${baseImageUrl}${items[activeItem].id}`"
      class="mx-auto shadow rounded"
      width="360"
      height="480"
    />
    <div class="max-w-full flex overflow-x-scroll px-3 py-1 mb-3">
      <img
        v-for="(item, index) in items"
        :key="index"
        width="82"
        height="82"
        :src="`${baseThumbUrl}${item.id}`"
        @click="activeItem = index"
        :class="[activeItem === index ? 'border-teal-700' : 'border-transparent']"
        class="p-1 cursor-pointer border-b-4 hover:border-teal-700"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeItem: 0,
      baseThumbUrl:
        'https://res.cloudinary.com/kt/image/upload/ar_1:1,c_fill,f_auto,q_75,w_82/v1536060727/massage/',
      baseImageUrl:
        'https://res.cloudinary.com/kt/image/upload/ar_3:4,c_fill,f_auto,q_75,h_720/v1536060727/massage/',
      items: [
        { id: 'thai-00', videoId: 'thai' },
        { id: 'thai-01' },
        { id: 'thai-02' },
        { id: 'thai-03' },
        { id: 'thai-04' },
        { id: 'thai-05' },
        { id: 'thai-06' },
        { id: 'thai-07' },
        { id: 'thai-08' },
        { id: 'thai-09' },
        { id: 'thai-10' },
        { id: 'thai-11' },
      ],
    };
  },
};
</script>
