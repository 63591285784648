<template>
  <div>
    <form @submit.prevent="submit" method="post">
      <div class="flex flex-wrap">
        <div class="flex flex-col sm:flex-no-grow">
          <h2 class="text-lg mx-4 mt-4 font-medium">Gewünschter Termin</h2>
          <div class="bg-white rounded shadow mx-4 my-2">
            <date-picker v-model="date"></date-picker>
          </div>
          <div class="bg-white rounded shadow mx-4 my-2">
            <time-picker v-model="time" :options="timeOptions"></time-picker>
          </div>
        </div>
        <div class="flex flex-col flex-1">
          <h2 class="text-lg mx-4 mt-4 font-medium">Massage</h2>
          <div class="bg-white rounded shadow mx-4 my-2 h-72 overflow-y-scroll">
            <massage-picker v-model="massage"></massage-picker>
          </div>
          <h2 class="text-lg mx-4 mt-4 font-medium">Kontaktdaten</h2>
          <div class="bg-white rounded shadow mx-4 my-2 p-4">
            <div class="mb-4 mx-1">
              <label for="name" class="pl-2">Name</label>
              <input
                id="name"
                name="name"
                type="text"
                v-model="name"
                placeholder="Vor- und Nachname"
                required
                class="input"
              >
            </div>
            <div class="mb-4 mx-1">
              <label for="email" class="pl-2">E-Mail</label>
              <input
                id="email"
                name="email"
                type="email"
                v-model="email"
                placeholder="example@domain.com"
                required
                class="input"
              >
            </div>
            <div class="mb-4 mx-1">
              <label for="phone" class="pl-2">Telefon</label>
              <input
                id="phone"
                name="phone"
                type="tel"
                v-model="phone"
                placeholder="+49 123 4567890"
                required
                class="input"
              >
            </div>
            <div class="mb-4 mx-1">
              <label for="note" class="pl-2">Nachricht (optional)</label>
              <textarea
                id="note"
                name="note"
                rows="2"
                v-model="info"
                placeholder="Hinweise oder besondere Wünsche?"
                class="input"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end m-4">
        <button
          type="submit"
          :disabled="disable"
          class="btn text-white bg-teal-700 hover:bg-teal-900 focus:bg-teal-900"
        >Anfrage senden</button>
      </div>
    </form>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';
import MassagePicker from '@/components/MassagePicker';

export default {
  components: {
    DatePicker,
    TimePicker,
    MassagePicker,
  },

  props: ['disable'],

  data() {
    return {
      timeOptions: [
        ['10:00', '10:30'],
        ['11:00', '11:30'],
        ['12:00', '12:30'],
        ['13:00', '13:30'],
        ['14:00', '14:30'],
        ['15:00', '15:30'],
        ['16:00', '16:30'],
        ['17:00', '17:30'],
        ['18:00', '18:30'],
        ['19:00', ''],
      ],
    };
  },

  computed: {
    date: {
      get() {
        return this.$store.state.appointment.date;
      },
      set(value) {
        this.$store.commit('updateDate', value);
      },
    },
    time: {
      get() {
        return this.$store.state.appointment.time;
      },
      set(value) {
        this.$store.commit('updateTime', value);
      },
    },
    massage: {
      get() {
        return this.$store.state.massage;
      },
      set(value) {
        this.$store.commit('updateMassage', value);
      },
    },
    name: {
      get() {
        return this.$store.state.contact.name;
      },
      set(value) {
        this.$store.commit('updateName', value);
      },
    },
    email: {
      get() {
        return this.$store.state.contact.email;
      },
      set(value) {
        this.$store.commit('updateEmail', value);
      },
    },
    phone: {
      get() {
        return this.$store.state.contact.phone;
      },
      set(value) {
        this.$store.commit('updatePhone', value);
      },
    },
    info: {
      get() {
        return this.$store.state.contact.info;
      },
      set(value) {
        this.$store.commit('updateInfo', value);
      },
    },
    massageOptions() {
      return this.$store.state.massageTypes;
    },
  },

  methods: {
    submit() {
      const data = {
        appointment: this.$store.state.appointment,
        massage: this.$store.state.massage,
        contact: this.$store.state.contact,
        extern: false,
        timestamp: { '.sv': 'timestamp' },
      };
      this.$emit('submit', JSON.parse(JSON.stringify(data)));
    },
  },
};
</script>
