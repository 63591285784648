<template>
  <div class="flex flex-col">
    <section class="flex-grow w-full max-w-5xl mx-auto py-4">
      <h1 class="heading text-2xl text-center mx-4 mb-4">Anfrage erfolgreich gesendet!</h1>
      <p
        class="m-4 text-lg text-center"
      >Vielen Dank für Ihre Anfrage. Wir werden diese schnellst­mög­lich bearbeiten.</p>
      <div class="max-w-md mx-auto p-4">
        <img
          src="https://res.cloudinary.com/kt/image/upload/v1541519789/massage/schedule.svg"
          alt="Terminkalender"
        >
      </div>
      <div class="m-4 text-right">
        <router-link :to="{ name: 'home' }" class="text-black">zurück zur Starseite</router-link>
      </div>
    </section>
    <contact></contact>
  </div>
</template>

<script>
import Contact from '@/components/Contact';

export default {
  components: {
    Contact,
  },
};
</script>
