<template>
  <div>
    <h2 class="text-lg mx-4 mt-4 font-medium">Warenkorb</h2>
    <div class="bg-white rounded shadow mx-4 my-2 sm:h-60 overflow-y-scroll">
      <div
        v-if="items.length < 1"
        class="flex items-center text-center h-full px-8 py-4 text-gray-400"
      >
        Massage auswählen und dem Warenkorb hinzufügen.
      </div>
      <div v-for="(item, i) in items" :key="i" class="px-4 py-2">
        <div class="flex justify-between">
          <div class="font-semibold">Gutschein</div>
          <div>{{ formatPrice(item.price) }}</div>
        </div>
        <div class="flex justify-between items-start text-sm">
          <div>
            {{ item.name }}
            <span v-if="item.duration" class="whitespace-nowrap">({{ item.duration }} Min.)</span>
          </div>
          <button
            @click="removeAt(i)"
            type="button"
            class="-mx-2 -mb-1 px-2 py-1 text-gray-500 hover:text-red-700"
          >
            <app-icon icon="icon-close" class="h-4 w-4"></app-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="bg-white rounded shadow mx-4 mb-2 flex justify-between px-4 py-2">
      <div class="font-semibold text-gray-500">Summe</div>
      <div class="font-semibold">{{ formatPrice(total) }}</div>
    </div>
  </div>
</template>

<script>
import AppIcon from '@/components/AppIcon';

export default {
  components: {
    AppIcon,
  },

  computed: {
    items() {
      return this.$store.state.cart;
    },
    total() {
      return this.$store.getters.total
    },
  },

  methods: {
    removeAt(index) {
      this.$store.commit('removeFromCart', index);
    },
    formatPrice(value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(value);
    },
  },
};
</script>
