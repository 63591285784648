<template>
  <ul class="list-reset p-2 sm:block flex flex-wrap">
    <li
      v-for="(timePair, pairIndex) in options"
      :key="pairIndex"
      class="flex justify-around flex-grow"
    >
      <div v-if="time" v-for="(time, index) in timePair" :key="index" class="relative">
        <input
          type="radio"
          :id="`time-${pairIndex}-${index}`"
          name="time"
          :checked="time === value"
          @change="$emit('input', time)"
          class="absolute h-full opacity-0 pointer-events-none"
        >
        <label
          :for="`time-${pairIndex}-${index}`"
          :class="[time === value ? 'bg-teal-700 text-white' : 'hover:bg-gray-300']"
          class="block w-24 text-center py-2 mx-1 my-1 font-semibold cursor-pointer rounded"
        >{{ time }}</label>
      </div>
      <div v-else class="w-24"></div>
    </li>
  </ul>
</template>

<script>
import AppIcon from '@/components/AppIcon';

export default {
  components: {
    AppIcon,
  },

  props: ['value', 'options'],
};
</script>
