<template>
  <div class="py-4">
    <div
      :id="category.id"
      v-for="(category, i) in categories"
      :key="category.id"
      :class="[i % 2 ? '': 'flex-row-reverse']"
      class="sm:flex sm:items-start pb-12 pt-4"
    >
      <img
        v-if="category.id != 'thai'"
        :src="`${baseImageUrl}${category.id}`"
        :alt="category.label"
        width="194"
        height="194"
        class="m-4 block shadow rounded"
      />
      <div class="px-4">
        <h3 class="text-xl font-medium tracking-wide uppercase">{{ category.label }}</h3>
        <component :is="componentMap[category.id]"></component>
        <ul class="list-reset max-w-md">
          <li v-for="type in category.types" :key="type.id" class="flex items-end py-1">
            <div class="flex flex-wrap flex-grow">
              <div v-if="type.id == 'special-both-80'"><span class="text-lg tracking-wide font-semibold my-2">Gesamtangebot </span><span>(beide Anwendungen)</span></div>
              <div class="font-semibold pr-1">{{ type.name }}</div>
              <div class="flex flex-grow">
                <div>({{ type.duration }} Min.)</div>
                <div class="mx-2 mb-1 border-b border-grey-light flex-grow"></div>
              </div>
            </div>
            <div :class="[type.id == 'special-both-80' ? 'font-semibold' : '']">{{ formatPrice(type.price) }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TabTraditional from '@/components/TabTraditional';
import TabSpecial from '@/components/TabSpecial';
import TabPantaiLuar from '@/components/TabPantaiLuar';
import TabLomiLomi from '@/components/TabLomiLomi';
import TabAyurvedic from '@/components/TabAyurvedic';
import TabThai from '@/components/TabThai';

export default {
  components: {
    TabTraditional,
    TabSpecial,
    TabPantaiLuar,
    TabLomiLomi,
    TabAyurvedic,
    TabThai,
  },

  data() {
    return {
      baseImageUrl:
        'https://res.cloudinary.com/kt/image/upload/ar_1:1,c_fill,f_auto,q_85,w_388/v1536060727/massage/',
      componentMap: {
        traditional: TabTraditional,
        special: TabSpecial,
        pantai: TabPantaiLuar,
        lomi: TabLomiLomi,
        ayurvedic: TabAyurvedic,
        thai: TabThai,
      },
    };
  },

  computed: {
    categories() {
      return this.$store.state.massageCategories;
    },
  },

  methods: {
    formatPrice(value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(value);
    },
  },
};
</script>
