<template>
  <div id="thai">
    <img
      :src="`https://res.cloudinary.com/kt/image/upload/ar_1:1,c_fill,f_auto,q_85,w_388/v1602243773/massage/thai`"
      alt="Mimi"
      width="130"
      height="130"
      class="float-right -mt-2 mx-4 block shadow rounded"
    />
    <p
      class="my-2"
    >Mimi ergänzt das Team auf dem Wellnessdeck und bietet Ihnen professionelle Thai Massagen an.</p>
    <p
      class="my-2"
    >Mimi wurde auf der Chiva-Som International Academy in Bangkok ausgebildet. Anschließend arbeitete sie mehrere Jahre im 5-Sterne Oriental Hotel in Bangkok und im 5-Sterne Hyatt Hotel in Dubai.</p>
    <p
      class="my-2"
    >Die traditionelle Thai Massage ist eine Ganzkörpermassage. Es wird Druck mithilfe der Hände, Ellenbogen und Füße ausgeübt. Zudem sind leichte Dehnübungen Bestandteil der Massage. Körper und Geist entspannen sich und ein tiefes Wohlgefühl tritt ein.</p>
    <thai-gallery class="mt-4"/>
  </div>
</template>

<script>
import ThaiGallery from '@/components/ThaiGallery';
export default {
  components: {
    ThaiGallery,
  },
};
</script>
