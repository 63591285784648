import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Request from './views/Request.vue';
import Privacy from './views/Privacy.vue';
import DateIntern from './views/DateIntern.vue';
import DateExtern from './views/DateExtern.vue';
import Success from './views/Success.vue';
import Legal from './views/Legal.vue';
import Shop from './views/Shop.vue';
import ShopSuccess from './views/ShopSuccess.vue';
import HeaderLanding from './views/HeaderLanding.vue';
import HeaderSimple from './views/HeaderSimple.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      components: {
        header: HeaderLanding,
        default: Home,
      },
    },
    {
      path: '/anfrage',
      components: {
        header: HeaderSimple,
        default: Request,
      },
      children: [
        {
          path: 'intern',
          name: 'date-intern',
          component: DateIntern,
        },
        {
          path: 'extern',
          name: 'date-extern',
          component: DateExtern,
        },
      ],
    },
    {
      path: '/anfrage/erfolg',
      name: 'success',
      components: {
        header: HeaderSimple,
        default: Success,
      },
    },
    {
      path: '/datenschutz',
      name: 'privacy',
      components: {
        header: HeaderSimple,
        default: Privacy,
      },
    },
    {
      path: '/impressum',
      name: 'legal',
      components: {
        header: HeaderSimple,
        default: Legal,
      },
    },
    {
      path: '/shop',
      name: 'shop',
      components: {
        header: HeaderSimple,
        default: Shop,
      },
    },
    {
      path: '/shop/erfolg',
      name: 'shop-success',
      components: {
        header: HeaderSimple,
        default: ShopSuccess,
      },
    },
    { path: '*', redirect: { name: 'home' } },
  ],
});
