<template>
  <svg class="block fill-current flex-shrink-0">
    <use :xlink:href="`#${icon}`"></use>
  </svg>
</template>

<script>
export default {
  name: 'AppIcon',

  props: ['icon'],
};
</script>
